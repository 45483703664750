<template><section /></template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: "ValidatePermissionRoles",
  props: ["view_name"],
  mounted() {
    this.setAccess();
  },
  computed: {
    ...mapState(["role_permission", "active_view"]),
  },
  watch: {
    role_permission: {
      deep: true,
      handler(val) {
        this.setAccess();
      }
    },
  },
  methods: {
    ...mapMutations(["setCurrentPermissionsView"]),

    setAccess() {
      let view_role_access = this.role_permission.module_permissions.find(el => el.view_name == this.view_name);
      let access_obj = {};

      console.log("modulo->", view_role_access);
      if (view_role_access) {
        view_role_access.permissions.forEach((el, index, array) => {
          access_obj[el] = array.includes(el);
        });
      };
      console.log("permisos->", access_obj);
      this.setCurrentPermissionsView(access_obj);
    }
  }
}
</script>
