<template>
  <section class="auth-layout">
    <AuthNav
      :route="$route"
      :token="token"
      :platform_name="platform_name"
      :registerData="registerData"
    />
    <div class="container">
      <SlideYUpTransition mode="out-in" origin="center top">
        <router-view />
      </SlideYUpTransition>
    </div>
    <div class="bg_sm"></div>
  </section>
</template>

<script>
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import { SlideYUpTransition } from "vue2-transitions";
import { getCurrentEcommercePlatform } from "@/utils/utils";
import AuthNav from "@/components/AuthNav";

export default {
  name: "auth-layout",
  components: {
    SlideYUpTransition,
    AuthNav
  },
  data() {
    return {
      token: "",
      shop_domain: ""
    };
  },
  created() {
    console.log("this.$route.query->", this.$route.query);
    let platform = getCurrentEcommercePlatform();
    this.getPlatformName(platform);
    console.log("platform->", platform);

    if (platform === "jumpseller") {
      this.getPlatformCode(this.$route.query.code);
    }
  },
  mounted() {
    this.getIntegrationQuery();
  },
  computed: {
    ...mapState(["logged", "platform_name", "platform_code", "registerData"])
  },
  methods: {
    ...mapMutations(["getPlatformName", "getPlatformCode", "getPlatformShop", "getPlatformAccess"]),

    async getIntegrationQuery() {
      this.token = this.$route.query.access_token;
      this.shop_domain = this.$route.query.shop_domain;

      if (this.platform_name === "shopify") {
        this.getPlatformShop(this.$route.query.shop);
        this.getPlatformAccess(this.$route.query.access_token);
        let code = this.$route.query.code;
        this.shop_domain = this.$route.query.shop;

        // se verifica si el token access ya estaba presente
        if (this.$route.query.access_token) {
          // console.log("🚀 Aqui *** -> this.$route.query.access_token", this.$route.query.access_token);
          this.token = this.$route.query.access_token;
        } else {
          let response = await axios.post(`${environment.socket_url}/shopify/access_token`, {
            shop: this.shop_domain,
            code
          });

          this.token = response.data.payload.access_token;
          this.getPlatformAccess(this.token);
        }
      }

      // if (this.$route.query.token_access) {
      //   this.token = this.$route.query.token_access;
      // } else {
      else if (this.platform_name === "jumpseller") {
        axios
          .post(
            `https://accounts.jumpseller.com/oauth/token?grant_type=authorization_code&client_id=c7176f690639bb06f46480e2cb9e3ca4986c79df1bdb996ac41f9b758e62066b&client_secret=0f0e494b74843a0cbe135d387bbc350beaf7961d9053da84a76528716bc5dde7&code=${this.platform_code}&redirect_uri=https://ia.databot.cl/registro?shop=jumpseller`
          )
          .then(response => {
            this.token = response.data.access_token;
          });
      }
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
.auth-layout {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 0;
  padding-bottom: 5rem;
  
  @media (max-width: 1600px) {
    height: 110vh;
  }
  .bg_sm {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, #ffffff 0%, #f8fbff 100%);
    z-index: -1;
  }
}
</style>
