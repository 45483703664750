import trae from 'trae';

let url_env = '';
let socket_url = '';
let url_env_bot = '';
const { origin } = window.location;

if (origin == 'https://dashboard-front-qa.herokuapp.com') {
  // url_env = 'https://api.databot.cl'
  url_env = 'https://qapi.databot.cl';
  url_env_bot = 'https://chatbot-front-qa.herokuapp.com';
  socket_url = 'https://chatbot-front-qa.herokuapp.com';
} else if (process.env.NODE_ENV === 'development') {
  url_env = 'http://localhost:3000';
  // url_env = 'https://api.databot.cl'
  socket_url = 'http://localhost:5000';
  url_env_bot = 'http://localhost:8000';
} else {
  // url_env = "https://databot-dashboard-api.herokuapp.com";
  url_env = 'https://api.databot.cl';
  socket_url = 'https://databot-chatbot-backend.herokuapp.com';
  url_env_bot = 'https://databot-chatbot-backend.herokuapp.com';
}

trae.baseUrl(socket_url);
const defaultBody = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  // socket_url: socket_url,
  // url_env_bot: url_env_bot,
};
const token = localStorage.getItem('token');
if (token) defaultBody.headers.Authorization = `Bearer ${token}`;
trae.defaults(defaultBody);
const chatbot_api = trae.create();

export default chatbot_api;
