<template>
  <section class="sub_menu-lvl2">
    <div v-for="(item, index) in filterItems" :key="index">
      <template v-if="item.path">
        <router-link class="item-link" :to="`${item.path}`"
          ><span class="mr-2">{{ index + 1 }}.</span>{{ item.name }}
        </router-link>
      </template>
      <template v-if="item.sub_category">
        <span
          @click="showDropCategory(item)"
          :class="{
            'item-link arrow': true,
            'arrow-2': item.category,
            'item-link-active': item.category,
          }"
          ><span class="mr-2">{{ index + 1 }}.</span>{{ item.name }}</span
        >
        <SlideYUpTransition :duration="200">
          <SubMenuLvl3 v-show="item.category" :info="item" />
        </SlideYUpTransition>
      </template>
    </div>
    <button
      v-if="selected_bot.has_skill_base"
      class="btn btn-base sm mx-3 my-4"
      @click="completeSkill()"
      :disabled="loadingComplete"
    >
      <template v-if="loadingComplete">
        <i class="fas fa-spinner fa-spin"></i> Actualizando...
      </template>
      <template class="btn-act" v-else>Actualizar chatbot</template>
    </button>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SubMenuLvl3 from './SubMenuLvl3';
import { SlideYUpTransition } from 'vue2-transitions';
import businessTypes from '@/services/api/dt_client_business_types.js';
import { reverseString } from '@/utils/utils';

export default {
  props: ['info'],

  components: {
    SlideYUpTransition,
    SubMenuLvl3,
  },
  data() {
    return {
      loadingComplete: false,
      skill_base: [],
      interactions: [
        // {
        //   name: 'Plantillas',
        //   category: false,
        //   sub_category: true,
        // },
        {
          name: 'Precargadas',
          path: ``, // configurada en initialize
          category: false,
          sub_category: false,
        },
        {
          name: 'Personalizadas',
          path: '/dashboard/chatbot/preguntas',
          category: false,
          sub_category: false,
        },
        {
          name: 'Menú Inicial',
          path: '/menu-inicial',
          category: false,
          sub_category: false,
        },
      ],
      incidents: [
        {
          name: 'Reportar',
          path: '/report-incident',
          category: false,
          sub_category: false
        },
        {
          name: 'Segumiento',
          path: '/follow-incident',
          category: false,
          sub_category: false
        }
      ],
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapState(['selected_bot', 'skill_template']),

    filterBotSkillBase() {
      if (this.selected_bot) {
        return !this.selected_bot.has_skill_base
          ? this.interactions.filter(el => el.name == 'Personalizadas')
          : this.interactions;
      }
    },
    filterItems() {
      switch (this.info.name) {
        case 'Interacciones':
          return this.filterBotSkillBase;
          break;
        case 'Incidencia':
          return this.incidents;
          break;
        default:
          return [];
          break;
      }
    },
  },
  methods: {
    ...mapMutations(['setRouterSkillBase']),

    async completeSkill() {
      try {
        this.loadingComplete = true; // Mostrar indicador de carga botón
        await this.$store.dispatch('dtClientSkillsModule/skillCreate', {
          bot_id: this.selected_bot.id,
          client_name: `${this.selected_bot.id}-PROD`,
          template: this.skill_template.value,
        });
        let uploadSkill = await this.$store.dispatch(
          'dtClientSkillsModule/skillUploadLLM',
          {
            bot_id: this.selected_bot.id,
          },
        );
        let assistantId = uploadSkill.payload.assistant_id;
        let inverseAssistantId = reverseString(assistantId);
        let allBusinessTypes = await this.$store.dispatch(
          'dtClientBusinessTypeModule/businessTypesListAll',
        );
        allBusinessTypes = allBusinessTypes.map(bot => bot.assistant_id);
        if (!allBusinessTypes.includes(inverseAssistantId)) {
          let createBusinessTypeId = await this.$store.dispatch(
            'dtClientBusinessTypeModule/businessTypeCreate',
            {
              name: `${this.selected_bot.id}-PROD`,
              show: false,
              product_type: 'chatbot',
              has_persistence: true,
              icon: '',
              activated: true,
              custom: true,
              api_key: '',
              assistant_id: assistantId,
              skill_id: '',
            },
          );
          let newBusinessTypeId = createBusinessTypeId.id;
          console.log('createdbusinessTypeId: ', newBusinessTypeId);
          await businessTypes.updateBusinessType(this.selected_bot.id, {
            chatbot: {
              id: this.selected_bot.id,
              business_types_id: newBusinessTypeId,
            },
          });
        }
        this.loadingComplete = false; // Ocultar indicador de carga botón
        this.$swal({
          icon: 'success',
          title: 'Chatbot Actualizado',
          timer: 1000,
          showConfirmButton: false,
        });
      } catch (error) {
        this.loadingComplete = false;
      }
    },
    showDropCategory(item) {
      item.category == false
        ? this.interactions.find(el =>
            el.name == item.name ? (el.category = true) : '',
          )
        : this.interactions.find(el =>
            el.name == item.name ? (el.category = false) : '',
          );
    },
    async initialize() {
      console.log("skill_template---->", this.skill_template);

      let data = (
        await this.$store.dispatch(
          'dtSectionsModule/listByTemplateName',
          this.skill_template.value,
        )
      ).sectionsFront;
      console.log('SKILL TEMPLATE: ', this.skill_template.value);
      console.log('🚀 Aqui *** -> data:', data);

      if (data) {
        this.skill_base = data.map(el => ({
          name: el.section_ui_name,
          path: `/chatbot-center/skill-base/${el.section_ui_name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .split(' ')
            .join('-')}`,
          original_name: el.section_name,
          bot_id: this.selected_bot.id,
        }));
        this.setRouterSkillBase(this.skill_base);
        console.log('skillbase->', this.skill_base);

        // Para settear primera selección del nav
        let set_item = this.interactions.find(el => el.name == 'Precargadas');

        if (this.skill_base && this.skill_base.length > 0) {
          let first = this.skill_base[0];

          set_item.path = `${first.path}?name=${first.original_name}`;
        }
      }
      // new option
      // this.skill_base.push({
      //   name: 'Actualizar chatbot',
      //   path: `/chatbot-center/terminar`,
      //   original_name: 'Terminar',
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.sub_menu-lvl2 {
  background-color: #f8fbff;
  // background-color: #DEEDFF;
  width: 100%;

  .bullet {
    width: 5px;
    height: 5px;
    border-radius: 1rem;
    background-color: #181818;
  }

  .item-link {
    justify-content: flex-start;

    &:hover {
      background-color: #deedff;
    }
  }
}
</style>
