<template>
  <section class="mobile">
    <div
      v-show="show"
      :class="{ 'mobile-menu mobile': true, 'c-fade-in': show }"
    >
      <span class="i-btn i-close i-btn-sm btn-position" @click="$emit('close', !show)" />
      <div class="items-box my-3">
        <router-link
          v-for="(item, index) in items"
          :key="index"
          :to="item.path"
        >
          <div class="d-flex align-items-center item p-3" @click="showSubMenuMobile(item)">
            <img
              v-if="item.name != 'Cuenta'"
              class="icon-img mr-3"
              :src="item.icon"
              :alt="item.name"
            >
            <img
              v-else
              class="icon-img icon-img-md mr-3"
              :src="user.user_avatar ? user.user_avatar : user.image || item.icon"
              :alt="item.name"
            >
            <span class="link-item">{{ item.name }}</span>
          </div>
        </router-link>
      </div>
    </div>
    <SubMenu
      v-show="showSubMenu"
      :item="selected"
      :key="active_view"
      @show="showSubMenu = false"
      @getShowImplement="$emit('getShowImplementMobile')"
    />
  </section>
</template>

<script>
import SubMenu from "@/components/SidebarPlugin/SubMenu";

export default {
  props: ["user", "show", "items", "active_view", "showProfile"],

  components: {
    SubMenu
  },

  data() {
    return {
      showSubMenu: false,
      selected: {}
    }
  },
  methods: {
    showSubMenuMobile(item) {
      console.log("item->", item);
      this.selected = item;

      item.sub_menu ? this.showSubMenu = true : this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile {
  .mobile-menu, .sub-menu-box {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1rem;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 3rem 1rem 2rem;
    background-color: #181818;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  
    .icon-img {
      width: 1.5rem;
    }
    .icon-img-md {
      width: 35px;
      margin-left: -.5rem;
    }
    .item {
      border-radius: 1rem;
      transition: .25s;

      &:hover, &:focus {
        background-color: #333;
      }
    }
    .link-item {
      color: #f2f2f2;
    }
    .btn-position {
      position: absolute;
      top: 1rem;
      left: 1.5rem;
      width: 40px;
      height: 40px;
      z-index: 200;
      
      @media (max-width: 375px) {
        left: 1rem;
      }
    }
  }
  .mobile {
    @media (min-width: 1025px) {
      display: none !important;
    }
  }
  .c-fade-in {
    animation-name: custom;
    animation-duration: .25s;
  }
  @keyframes custom {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
