import assistant from '@/services/api/assistants.js';

const state = () => ({
  assistant: null,
});

const getters = {};
const actions = {
  async listOne({ commit }, assistant_id) {
    let resp = (await assistant.listOne(assistant_id)).data.payload;
    commit('setAssistant', resp);
  },
  async trainLlm({ commit }, assistant_id) {
    await assistant.trainLlm(assistant_id);
  },
  async generateFromWatson({ commit }, assistant_id) {
    await assistant.generateFromWatson(assistant_id);
  },
};
const mutations = {
  setAssistant(state, payload) {
    state.assistant = payload;
  },
};

const namespaced = true;

export default { getters, actions, mutations, state, namespaced };
