<template>
  <FadeTransition :duration="200">
    <section class="days-left" v-show="show" v-if="account">
      <div v-if="account.days_left > 0" class="row align-items-center mx-0">
        <p v-if="account.bot" class="info mb-0">
          <span @click="show = false" class="i-btn i-btn-xs i-btn-bg-w i-close mr-2"></span>
          Quedan
          <span class="negrita white">
            <!-- {{ account.days_left }} días</span> para que acabe la prueba gratis del plan profesional de <span class="negrita white"> -->
            {{ account.days_left }} días</span
          >
          para que acabe tu periodo de prueba en
          <span class="negrita white"> {{ account.bot.bot_name }}</span>
        </p>
        <!-- <button
          v-if="account.bot"
          class="btn btn-base sm"
          @click="UpgradePlan()"
          >Activar plan
        </button> -->
      </div>
      <div v-else class="row align-items-center mx-0">
        <p v-if="account.bot" class="info mb-0">
          <i @click="show = !show" class="fas fa-times-circle" />
          Terminó tu periodo de prueba con plan profesional de
          <span class="negrita white"> {{ account.bot.bot_name }} </span>.
        </p>
        <button
          v-if="account.bot"
          class="btn btn-base sm"
          @click="UpgradePlan()"
        >
          Actualizar plan
        </button>
      </div>
    </section>
  </FadeTransition>
</template>

<script>
import { mapState } from "vuex";
import { FadeTransition } from "vue2-transitions";
import dashboard_api from "../dashboard_api.js";

export default {
  name: "DaysLeft",

  components: {
    FadeTransition,
  },

  data() {
    return {
      show: false,
      account: {},
      my_register: {},
    };
  },

  computed: {
    ...mapState(["user", "selected_bot"]),
  },

  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        if (this.user.id) {
          this.getDays();
        }
      },
    },
  },

  methods: {
    getDays() {
      const self = this;
      dashboard_api
        .get(`/qvo_plans/by_user_id/${self.user.id}`)
        .then((qvoResponse) => {
          if (qvoResponse.data.length) {
            self.account = qvoResponse.data.find(
              (item) => item.test_period == true
            );
            self.show = qvoResponse.data.test_period;
            // this.bot_id = qvoResponse.data[0].bot_id;
          } else {
            dashboard_api
              .get(`/client_subscriptions/by_user_id/${self.user.id}/true`)
              .then((response) => {
                if (response.data) {
                  self.account = response.data;
                  self.show = response.data.test_period;
                  // self.bot_id = response.data.bot.id;
                }
              })
              .catch((error) => console.log("error ->", error));
          }
        });
    },
    UpgradePlan() {
      this.my_register.bot = this.selected_bot.id;

      // TODO Sistema de planes con flow deprecado
      // this.$router.push({
      //   name: "dashboard-planes",
      //   query: {
      //     seccion: "new",
      //     bot_id: this.selected_bot.id,
      //   },
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.days-left {
  position: absolute;
  width: fit-content;
  height: 50px;
  top: .8rem;
  left: 0;
  right: 0;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  background-color: #2981ef;
  box-shadow: 10px 10px 30px #DEEDFF;
  border-radius: 1rem;
  padding: 0 1rem;
  z-index: 10;

  @media (max-width: 1060px) {
    padding: 0.5rem;
    height: auto;
  }
  @media (max-width: 768px) {
    padding: 1rem 0.5rem;
    background-color: #2981ef;
    height: auto;
  }
  @media (max-width: 480px) {
    margin: 0 1rem;
  }
  .info {
    font-size: 14px;
    color: #fff;
    margin-right: 1rem;
    z-index: 10;

    @media (max-width: 1060px) {
      width: 80%;
      text-align: center;
      margin: 0 auto;
    }
  }
  .btn-base {
    width: 120px;

    @media (max-width: 640px) {
      margin: 1.5rem auto 0;
    }
  }
  .fa-times-circle {
    color: #fff;
    font-size: 1rem;
    margin-right: 0.5rem;
    transition: 0.5s;
    z-index: 10;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
