<template>
  <section :key="logged" class="main-buttons">
    <div class="button-bots desktop">
      <div class="bots-box">
        <!-- <ValidateDesactiveBots v-if="selected_bot" /> -->
        <span
          v-if="scrollPoint > 0"
          @click="scrolltoBots('left')"
          class="i-btn py-1 px-2 ml-3 mr-3"
          >{{ `<-` }}</span
        >
        <div
          :class="manyBots ? 'slide_box' : 'bots-box'"
          id="slide_box"
          ref="total_width"
        >
          <span
            v-for="(bot, index) in ownerBots"
            :key="index"
            :class="{
              'text-state text-state-md': true,
              selected: bot.id == selected_bot.id,
              desactived: bot.id == selected_bot.id && bot.activated == false,
            }"
            @click="selectedBot(bot)"
            >{{ bot.bot_name
            }}<i
              v-if="bot.id == selected_bot.id && bot.activated == false"
              @click="deleteChatbot"
              class="fas fa-times"
          /></span>
          <router-link to="/chatbot/new" class="btn-dash btn-dash-sm my-2 mr-3"
            >+</router-link
          >
        </div>
        <span
          v-if="manyBots && scrollPoint < setTotalWidth"
          @click="scrolltoBots('right')"
          class="i-btn py-1 px-2 mr-3"
          >{{ `->` }}</span
        >
      </div>
      <div class="d-flex align-items-center">
        <div
          v-if="ecommerceAccount.show_bar == 'true'"
          class="progress_bar mr-3"
        >
          <span class="text_xs mb-1">Cargando productos.. </span>
          <progress
            title="Cargando productos.."
            :class="{ done: ecommerceAccount.upload_progress >= 100 }"
            id="progress_products"
            max="100"
            :value="ecommerceAccount.upload_progress"
          />
        </div>
        <FadeTransition
          v-if="
            ecommerceAccount.show_done_bar == 'true' ||
            ecommerceAccount.show_done_bar == true
          "
          :duration="200"
        >
          <span class="text-state text-state-md done" @click="productsReady"
            >Productos cargados</span
          >
        </FadeTransition>
        <button
          v-show="checkCurrentView.toLowerCase() === 'chatbot-preview'"
          class="text-state text-state-md desktop mr-1"
          @click="copyPreviewLink()"
        >
          <span class="i-base i-st i-copy mr-2" />Copiar link para compartir
        </button>
        <router-link v-if="user_role.length > 0 && user_role != 'agente'" class="text-state text-state-md desktop mr-0" to="/preview">
          <span class="i-base i-st i-chatbot mr-2" />Previsualizar
        </router-link>
        <RolMenu :mobile="false" v-if="show_assigned_button == true" />
      </div>
    </div>
    <!-- Menu mobile -->
    <div
      :class="{
        'menu-mobile mobile': true,
        'no-icon-view': !show_assigned_button,
      }"
    >
      <span
        @click="setShowSidebarMobile(!showSidebarMobile)"
        class="i-btn i-menu"
      />
      <select
        v-if="ownerBots"
        v-model="mobile_selected_bot"
        @change="selectedBot(mobile_selected_bot)"
      >
        <option v-for="(bot, index) in ownerBots" :key="index" :value="bot">
          {{ bot.bot_name }}
        </option>
      </select>
      <RolMenu :mobile="true" v-if="show_assigned_button == true" />
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import dashboard_api from '../dashboard_api.js';
import dashboard_api_v2 from '@/dashboard_api_v2';
import ValidateDesactiveBots from '@/components/ValidateDesactiveBots';
import DaysLeft from '@/components/DaysLeft';
import RolMenu from '@/components/SidebarPlugin/RolMenu';
import socket from '@/plugins/sockets';
import socketTest from '@/plugins/socketAutomatizaciones';
import { FadeTransition } from 'vue2-transitions';

export default {
  components: {
    ValidateDesactiveBots,
    DaysLeft,
    RolMenu,
    FadeTransition,
    socketTest,
  },
  data() {
    return {
      plan_name: '',
      bots: [],
      loading: false,
      showSidebar: false,
      mobile_selected_bot: {},
      scrollPoint: 0,
      setTotalWidth: 0,
      show_assigned_button: false,
      botIds: [],
      generic_permissions: {
        read: true,
        add: false,
        delete: false,
        edit: false,
        export: false,
        'send-wsp': false,
        'shunt-agent': false
      },
      permissons_by_bot: [],
    };
  },
  computed: {
    ...mapState([
      'logged',
      'user',
      'active_view',
      'ownerBots',
      'selected_bot',
      'showSidebarMobile',
      'ecommerceAccount',
      'role_permission',
      'registerData',
      'user_role'
    ]),
    changePreview() {
      return this.$route.name == 'chatbot-preview';
    },
    manyBots() {
      return this.ownerBots.length > 8;
    },
    checkCurrentView() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapMutations([
      "setSelectedBot",
      "setPlanName",
      "setShowSidebarMobile",
      "setPermissions",
      "setEcommerceAccount",
      "setCurrentPermissionsView",
      "setActiveView",
      "setRegisterProcess",
      "setAllRoles",
      "logout",
      "setUserRole"
    ]),
    async setViewPermissions() {
      try {
        let permissons_by_bot = (await dashboard_api.post(`/userbot/find_user_bot_with_permissions/${this.$store.state.user.id}`, { bot_ids: this.botIds })).data;
        if (permissons_by_bot && permissons_by_bot.length > 0) {
          let selected_bot_permissons = permissons_by_bot.find(el => el.bot_id == this.selected_bot.id);
          console.log("0 ->", permissons_by_bot);
          console.log("1 role->", selected_bot_permissons.role);
          // console.log("1.2 role name->", selected_bot_permissons.role.name);
          // console.log("1.3 role modules->", selected_bot_permissons.role.permissions[0].modules);
          // save current role name
          this.setUserRole(selected_bot_permissons.role.name);
          // control de array vacío ->
          if (selected_bot_permissons.role.permissions && selected_bot_permissons.role.permissions.length > 0) {
            // console.log("4->", selected_bot_permissons.role.permissions[0].module_permissions);
            this.setPermissions(selected_bot_permissons.role.permissions[0]);
          } else {
            this.setPermissions({});
            console.log("2 access_obj->", this.generic_permissions);
            this.setCurrentPermissionsView(this.generic_permissions);
          }
        }
      } catch (err) {
        this.setPermissions({});
        console.log("3 catch->", err);
        this.setCurrentPermissionsView(this.generic_permissions);
      }
    },
    productsReady() {
      this.$swal({
        icon: 'success',
        title: '¡Productos cargados con éxito!',
        text: 'Tu chatbot ahora podrá recomendar los productos de tu inventario. Para mejorar la experiencia que tu chatbot entregará, configura sus respuestas en Databot Center.',
        showConfirmButton: true,
      }).then((el) => {
        if (el.isConfirmed) {
          this.setEcommerceAccount({
            show_bar: null,
            show_done_bar: false,
            upload_progress: 0,
          });
        }
      });
    },
    async getBotIds() {
      console.log("getBotIds--->");
      try {
        this.botIds = (await dashboard_api.post('/bot/list_bot_ids_by_user', {
          user_id: this.$store.state.user.id,
        })).data;
      } catch {
        (err) => console.log("err ->", err);
      }
      if (this.botIds?.length > 0) {
        this.botIds.forEach(botId => {
          socket.emit('AUTO_RECONNECT', {
            botId
          });
          socketTest.emit('AUTO_RECONNECT', {
            botId
          });
        });
        // colocando en store los botIds y sus business_types
        let resp = (await dashboard_api_v2.get(`bots/get_bot_info/${this.botIds}`)).data;
        this.$store.commit('setBotsWithBusinessTypeShow', resp);
      } else {
        setTimeout(() => {
          this.$swal({
            icon: 'info',
            title: 'No tienes chatbots registrados.',
            text: 'Puedes crear un chatbot o volver a ingresar.',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: 'Salir',
          }).then(resp => {
            if (resp.isConfirmed) {
              let my_register = this.registerData;
              my_register.step = 2;
              this.setRegisterProcess(my_register);
              this.$router.push({
                name: 'register',
                query: { name: 'primer-chatbot' },
              });
            } else {
              this.logout();
              this.$router.push('/login');
            }
          });
        }, 2500);
      }
    },
    scrolltoBots(to) {
      if (to == 'left') {
        if (this.scrollPoint < 0) {
          this.scrollPoint = document.getElementById(
            'slide_box',
          ).scrollLeft = 0;
        } else {
          this.scrollPoint = document.getElementById(
            'slide_box',
          ).scrollLeft -= 136;
        }
      } else {
        this.scrollPoint = document.getElementById(
          'slide_box',
        ).scrollLeft += 136;
      }
    },
    async getBotPlan(bot) {
      try {
        this.plan_name = (
          await dashboard_api.get(`/client_subscriptions/by_bot_id/${bot.id}`)
        ).data.admissible_plan.name;
      } catch (error) {
        this.plan_name = '';
      }
      this.setPlanName(this.plan_name);
    },
    async selectedBot(bot) {
      this.setSelectedBot(bot);
      await this.getBotPlan(bot);
    },
    async getBots() {
      const self = this;
      let owner_bots = [];
      let assigned_bots = [];
      let resp = [];

      owner_bots = (await dashboard_api.get(`/bot/show_owned_bots/${self.user.id}`)).data;
      assigned_bots = (await dashboard_api.get(`/bot/show_assigned_bots/${self.user.id}`)).data;

      if (assigned_bots?.length > 0) {
        if (owner_bots?.length > 0) self.show_assigned_button = true;
        else self.show_assigned_button = false;
      } else self.show_assigned_button = false;

      if (self.active_view == 'my_bots') {
        if (owner_bots?.length > 0) resp = owner_bots;
        else self.setActiveView("assigned_bots");
      } else if (assigned_bots?.length > 0) {
        resp = assigned_bots;
      } else {
        this.$swal({
          icon: 'info',
          title: 'No tienes chatbots registrados.',
          text: 'Puedes crear un chatbot o volver a ingresar.',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Crear',
          cancelButtonText: 'Salir',
        }).then(resp => {
          if (resp.isConfirmed) {
            let my_register = this.registerData;
            my_register.step = 2;
            this.setRegisterProcess(my_register);
            this.$router.push({
              name: 'register',
              query: { name: 'primer-chatbot' },
            });
          } else {
            this.logout();
            this.$router.push('/login');
          }
        });
      };
      self.bots = resp;
      self.bots.sort((a, b) => (a.activated > b.activated ? -1 : 1));
      self.$store.commit('setOwnerBots', self.bots);
      console.log('selected bot->', self.bots[0]);
      await self.selectedBot(self.bots[0]);
      // TODO for roles ->
      let roles = [];
      if (self.selected_bot?.id) {
        roles = (await dashboard_api.get(`/role/role_bot/${self.selected_bot.id}`)).data;
        self.setAllRoles(roles);
        // console.log("roles->", roles);
      };
      self.getWidthPerBot(self.bots);
    },
    getWidthPerBot(bots) {
      let responsive_box;

      if (this.$refs.total_width) {
        responsive_box = this.$refs.total_width.clientWidth;
      }
      let width = 0;
      let totalBots = bots.length;
      let overFlowBots = 0;

      if (bots && responsive_box == 544) {
        overFlowBots = totalBots * 136 - 136 * 4;
        width = overFlowBots + 51;

        this.setTotalWidth = width;
      } else {
        overFlowBots = totalBots * 136 - 136 * 8;
        width = overFlowBots + 51;

        this.setTotalWidth = width;
      }
    },
    evaluateToShow(botId) {
      let showBot = true;
      return showBot;
    },
    copyPreviewLink() {
      let url = `${window.location.origin}/preview_bot/${this.selected_bot.id}?token=${this.selected_bot.token}&web=${this.selected_bot.web}`;
      navigator.clipboard.writeText(url);
      this.$swal({
        icon: 'success',
        title: 'Link copiado al portapapeles',
      });
    },
    deleteChatbot() {
      const self = this;
      const owner = self.active_view == 'my_bots';

      if (self.ownerBots.length > 1) {
        self
          .$swal({
            title: `${
              owner ? '¿Eliminar chatbot?' : '¿Desvincular del chatbot?'
            }`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Cancelar',
            cancelButtonText: `${owner ? 'Eliminar' : 'Devincular'}`,
          })
          .then((result) => {
            // invertido para que el usuario no borre el bot
            if (!result.isConfirmed) {
              if (self.active_view != 'assigned_bots') {
                // Se cancela el plan primero, si no da error se prosigue con eliminar el bot

                if (self.selected_bot.client_subscription == null) {
                  dashboard_api
                    .delete(`/bot/${self.selected_bot.id}`)
                    .then((data) => {
                      console.log('1-data->', data);
                      self.$swal({
                        icon: 'success',
                        title: 'El chatbot se eliminó correctamente.',
                        timer: 1000,
                        showConfirmButton: false,
                      });

                      let deleteBot = self.ownerBots.findIndex(
                        (el) => el.id == self.selected_bot.id,
                      );

                      self.ownerBots.splice(deleteBot, 1);

                      self.$store.commit('setOwnerBots', self.ownerBots);
                      self.$store.commit('setSelectedBot', self.ownerBots[0]);
                    });
                } else {
                  dashboard_api
                    .post('/flow_requests/cancel_subscription', {
                      id: self.selected_bot.client_subscription.id,
                    })
                    .then((cancelSubscriptionResponse) => {
                      if (cancelSubscriptionResponse.status == 800) {
                        self.$swal({
                          icon: 'error',
                          title:
                            'El chatbot se no se pudo eliminar correctamente, por favor contacta a soporte.',
                        });
                      } else {
                        dashboard_api
                          .delete(`/bot/${self.selected_bot.id}`)
                          .then((data) => {
                            console.log('2-data->', data);
                            self.$swal({
                              icon: 'success',
                              title: 'El chatbot se eliminó correctamente.',
                              timer: 1000,
                              showConfirmButton: false,
                            });
                            let newBots = self.ownerBots;

                            newBots.forEach((bot, index, array) => {
                              if (bot.id == self.selected_bot.id) {
                                array.splice(index, 1);
                              }
                            });
                            self.$store.commit('setOwnerBots', newBots);
                            self.$store.commit(
                              'setSelectedBot',
                              self.ownerBots[0],
                            );
                          });
                      }
                    })
                    .catch((error) => console.error('error > ', error));
                }
              } else {
                dashboard_api
                  .delete(
                    `/userbot/unlink_bot/${self.user.id}/${self.selected_bot.id}`,
                  )
                  .then((data) => {
                    self.$swal({
                      icon: 'success',
                      title: 'El Chatbot se desvinculó exitosamente',
                    });
                    self.bots.forEach((bot, index, array) => {
                      if (bot.id == self.selected_bot.id) {
                        array.splice(index, 1);
                      }
                    });
                  });
                location.reload();
              }
            }
          });
      } else {
        self.$swal({
          icon: 'error',
          title: 'Tu cuenta debe tener al menos un chatbot',
        });
      }
    },
  },
  watch: {
    'ecommerceAccount.upload_progress': function (val) {
      if (val >= 100) {
        this.$store.dispatch(
          'assistantsModule/trainLlm',
          this.selected_bot.business_type.assistant_id,
        );
        setTimeout(() => {
          this.ecommerceAccount.show_bar = false;
          this.ecommerceAccount.show_done_bar = true;

          this.setEcommerceAccount({
            show_bar: false,
            show_done_bar: true,
          });
        }, 4000);
      }
    },
    active_view: {
      immediate: true,
      handler(val) {
        if (this.logged == 'true') {
          this.getBots();
          this.getBotIds();
        }
      },
    },
    async selected_bot(val) {
      this.mobile_selected_bot = this.selected_bot;

      if (val?.id) {
        await this.setViewPermissions();

        // TODO Sistema de planes con flow deprecado
        // if (this.$route.query.seccion == 'mejorar-plan') {
        //   this.$router.push({
        //     name: 'dashboard-planes',
        //     query: {
        //       seccion: 'mejorar-plan',
        //       bot_id: val.id,
        //     },
        //   });
        // }
        if (this.$route.name == 'detail-lead') {
          this.$router.push({ name: 'crm-clients' });
        }
        // TODO Sistema de planes con flow deprecado
        // if (this.$route.name == 'dashboard-checkout') {
        //   this.$router.push({
        //     name: 'dashboard-checkout',
        //     query: {
        //       section: this.$route.query.section,
        //       plan: this.$route.query.plan,
        //       name: this.$route.query.name,
        //       period: this.$route.query.period,
        //       bot_id: val.id,
        //     },
        //   });
        // }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-buttons {
  display: flex;
  align-items: center;
  max-width: 100%;
  justify-content: space-between;
  width: 100%;
  .button-bots {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    margin: 1rem 0;
    padding: 0 2rem;
    z-index: 0;
    height: fit-content;
    width: 100%;

    @media (max-width: 960px) {
      display: block;
      justify-content: flex-start;
      height: auto;
    }
    @media (max-width: 640px) {
      padding: 0 0.5rem;
    }

    .bots-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 1279px) {
        width: 100%;
      }
    }

    .slide_box {
      display: flex;
      max-width: 1224px;
      width: 100%;
      overflow-x: scroll;
      scroll-behavior: smooth;

      @media (max-width: 1600px) {
        max-width: 544px;
      }
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      &::-webkit-scrollbar-thumb {
        visibility: hidden;
      }
    }
    .text-state-md {
      position: relative;
      text-align: center;
      min-width: 120px;
      margin: 0.5rem 1rem 0.5rem 0;
      z-index: 0;

      @media (max-width: 768px) {
        width: calc(33% - 1rem);
        margin: 0.5rem;
      }
      @media (max-width: 480px) {
        width: calc(50% - 1rem);
      }
      i {
        position: absolute;
        color: #b2b2b2;
        padding: 0.25rem 0.35rem;
        border: 1px solid #ff6565;
        background-color: #fff;
        color: #ff6565 !important;
        border-radius: 0.25rem;
        font-size: 0.5rem;
        right: -2px;
        z-index: 9;
        transition: 0.5s;
        outline: 2px solid #fff;
      }
    }
    .btn-dash-sm {
      margin: 0;

      @media (max-width: 960px) {
        margin: 0.5rem;
      }
    }
    .selected {
      transition: 0.5s;
      border-color: #2981ef;
      background-color: #fff;
      color: #2981ef !important;
    }
    .desactived {
      transition: 0.5s;
      border-color: #ff6565 !important;
      color: #ff6565 !important;
    }
  }
  .menu-mobile {
    display: grid;
    grid-template-columns: 40px 2fr 40px;
    column-gap: 1rem;
    align-items: center;
    padding: 1rem 2rem;
    width: 100%;

    @media (max-width: 375px) {
      padding: 1rem;
    }
    span {
      width: 100%;
      height: 100%;
    }
    select {
      width: 100%;
      display: inline-block;
      justify-content: center;
      align-self: center;
      -webkit-align-self: center;
      text-align: center;
      font-size: 14px;
      padding: 0.5rem;
      margin-bottom: 0;
    }
  }
  .no-icon-view {
    grid-template-columns: 40px 2fr;
  }
  .desktop {
    @media (max-width: 1024px) {
      display: none !important;
    }
  }
  .mobile {
    @media (min-width: 1025px) {
      display: none !important;
    }
  }
  .progress_bar {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
